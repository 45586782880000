<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-select
            outlined
            flat
            dense
            :items="listType"
            background-color="white"
            v-model="params.type"
            label="Type"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" v-if="params.type === 1">
          <v-autocomplete
            outlined
            flat
            dense
            :items="sites"
            item-text="name"
            item-value="name"
            background-color="white"
            v-model="params.site"
            :rules="[v => !!v || 'Site is required']"
            label="Site"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" v-if="params.type === 2">
          <v-text-field
            outlined
            flat
            dense
            background-color="white"
            v-model="params.site"
            :rules="[v => !!v || 'Site is required']"
            label="Site"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" v-if="params.type === 3">
          <v-autocomplete
            outlined
            flat
            dense
            :items="listPo"
            item-text="purchaseOrderNumber"
            item-value="id"
            background-color="white"
            v-model="params.poId"
            :rules="[v => !!v || 'PO is required']"
            label="PO"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-btn color="primary" @click="print" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="view">
      <iframe
        :src="url"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="700px"
      ></iframe>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import fileDownload from "js-file-download";

export default {
  name: "report-expense-kasbon",
  computed: {
    ...mapState("external", ["sites"]),
  },
  data() {
    return {
      params: {
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .endOf("month")
          .format("yyyy-MM-DD"),
        poId: 0,
        site: "",
        type: 0,
      },
      menuStartDate: false,
      menuEndDate: false,
      summary: [],
      employees: [],
      url: "",
      view: false,
      listType: [
        { text: "All", value: 0 },
        { text: "Site By WO", value: 1 },
        { text: "Site By Remark", value: 2 },
        { text: "PO", value: 3 },
      ],
      listPo: [],
    };
  },
  methods: {
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([await this.$store.dispatch("external/getSites"), this.getPO()])
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getPO() {
      await this.$store.dispatch("external/getSalePurchaseOrderAll", true).then(response => {
        this.listPo = response.data;
      });
    },
    async print() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/expenseKasbon", this.params)
        .then(response => {
          this.summary = response.data;
          this.createPdf();
          this.view = true;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/expenseKasbonExcel", this.params)
        .then(response => {
          fileDownload(response.data, `expense-kasbon.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-YY");
    },
    totalKasbon(items) {
      return items.map(x => x.totalKasbon).reduce((x, y) => x + y);
    },
    totalSettlement(items) {
      return items.map(x => x.totalSettlement).reduce((x, y) => x + y);
    },
    totalDifference(items) {
      return items.map(x => x.totalDifference).reduce((x, y) => x + y);
    },
    setNewArray(items) {
      const array = [];
      items.forEach((x, i) => {
        array.push([
          {
            content: i + 1,
            styles: { valign: "top", halign: "center" },
          },
          {
            content: x.kasbonNumber,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: this.formatDate(x.kasbonDate),
            styles: { valign: "top", halign: "left" },
          },
          {
            content: x.sites,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: this.formatPrice(x.totalKasbon),
            styles: { valign: "top", halign: "right" },
          },
          {
            content: this.formatPrice(x.totalSettlement),
            styles: { valign: "top", halign: "right" },
          },
          {
            content: this.formatPrice(x.totalDifference),
            styles: { valign: "top", halign: "right" },
          },
        ]);
      });
      return array;
    },
    createPdf() {
      const doc = new jsPDF();

      doc.setFont("Times", "bold");
      doc.setFontSize(10);
      doc.text("PT. GRAHA SUMBER PRIMA ELEKTRONIK", 75, 10);
      doc.text("Report :", 10, 30);
      doc.text("PENGELUARAN INSTALISASI", 25, 30);

      let y2 = 40;
      this.summary.forEach(x => {
        y2 += 10;
        doc.autoTable({
          startY: y2,
          margin: { left: 8 },
          theme: "plain",
          head: [["No", "Ref#", "Date", "Site", "Kasbon", "Realisasi", "Selisih"]],
          body: [
            ...this.setNewArray(x.items),
            [
              {
                colSpan: "4",
                content: `Total ${x.employeeName} `,
                styles: { valign: "top", halign: "right" },
              },
              {
                content: this.formatPrice(this.totalKasbon(x.items)),
                styles: { valign: "top", halign: "right" },
              },
              {
                content: this.formatPrice(this.totalSettlement(x.items)),
                styles: { valign: "top", halign: "right" },
              },
              {
                content: this.formatPrice(this.totalDifference(x.items)),
                styles: { valign: "top", halign: "right" },
              },
            ],
          ],
          pageBreak: "avoid",
          styles: {
            valign: "middle",
            lineWidth: 0.1,
            halign: "left",
            lineColor: "black",
            fontSize: 9,
          },
          didDrawPage: () => {
            let y = y2 - 10;
            y += 10;
            doc.text("Nama :", 10, y);
            doc.text(x.employeeName, 25, y);

            y2 += 20;
            if (y2 >= 240) {
              doc.addPage();
              y2 = 40;
            }
          },
        });
      });

      this.url = doc.output("datauristring");
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
